<template>
  <div class="seller-portfolio-wrapper">
    <div class="title">
      <div style="font-size: 20px; color: #2d3640; height: 52px; line-height: 52px">Tüm Değerlendirmeler</div>
      <div class="option-wrapper">
        <div style="margin: 10px; display: flex">
          <div v-if="userProfile && userProfile.is_seller" class="option" :class="enableFreelancerReviews ? 'active' : null" @click="enableFreelancerReviews = true">Freelancer Olarak</div>
          <div v-if="userProfile && userProfile.isBuyer" class="option" :class="!enableFreelancerReviews ? 'active' : null" @click="enableFreelancerReviews = false">Alıcı Olarak</div>
        </div>
      </div>
    </div>
    <div v-if="isSelfUser || grades.length" class="rates">
      <div class="symbols">
        <div class="wrapper">
          <div v-if="rerender" class="box" v-for="(grade, index) in grades" :key="index">
            <img class="review-icon" :src="grade.iconSVG" onload="SVGInject(this)"/>
            <div class="review-title" style="margin-top: 10px">
              {{ grade.name }}
            </div>
            <div class="review-stars" style="margin-top: 5px">
              <star-rating
                          :rating="grade.grade"
                           :max-rating="5"
                           :star-size=5
                           :show-rating="false"
                           :increment="0.5"
                           inactive-color="#bfc8d2"
                           active-color="#ffbf00"
                           :read-only="true"/>
            </div>
          </div>
        </div>
      </div>
      <div v-if="commentInfo.commentRating > 0 || isSelfUser" class="total-reviews">
        <template v-if="commentInfo.orderCount">
          <p style="font-size: 14px; margin-top: 4px; color: #8b95a1;">{{commentInfo.orderCount || 0}} sipariş</p>
          <p style="font-size: 16px; color: #2d3640; margin-top: 2px"><span style="font-weight: bold">{{commentInfo.commentCount || 0}}</span> değerlendirme</p>
        </template>
        <template v-else>
          <p style="font-size: 14px; margin-top: 4px; color: #8b95a1;">Henüz</p>
          <p style="font-size: 16px; color: #2d3640; margin-top: 2px">değerlendirme yok</p>
        </template>
        <p style="font-size: 42px; height: 52px; font-weight: bold; color: #ffbf00; margin-top: 5px">{{ commentInfo.commentRating.toFixed(2) || 0.00 }}</p>
        <p style="font-size: 12px; color: #bfc8d2;">5 üzerinden</p>
      </div>
    </div>
    <div v-if="commentInfo.comments.length" class="review-comments">
      <div v-for="(comment, index) in commentInfo.comments" :key="index">
        <div style="display: flex;">
          <router-link style="text-decoration: none;" :to="'/' + comment.username">
            <img style="float: left; width: 48px; height: 48px; border-radius: 50%;border:1px solid #bfc8d2;" :src="comment.avatar_url"/>
          </router-link>
          <div style="margin-left: 11px; margin-top: 4px; display: flex">
            <router-link style="text-decoration: none;" :to="'/' + comment.username">
              <p style="text-align: left; font-size: 16px; font-weight: 500; color: #4b4f52;">{{comment.username}}</p>
            </router-link>
            <div class="user-info-rating-div" style="margin-left: 5px" @click="showCommentDetail(comment.comment_id)">
              <img src="https://gcdn.bionluk.com/site/cicons/ic-star.svg" onload="SVGInject(this)" class="star-rating"/>
              <p class="rating-number">
                {{ comment.grade | convertNumberToFraction }}
              </p>
              <div class="star-detail-info-icon-wrapper"  style=" margin-top: 3.5px; margin-left: 3px; display: flex; ">
                <img style="width: 12px; height: 12px;" src="https://gcdn.bionluk.com/site/cicons/ic-info.svg" onload="SVGInject(this)" class="star-detail-info-icon"/>
              </div>
            </div>
            <div @focusout="hideCommentDetail" tabindex="99" :id="'rating-star-'+comment.comment_id" class="rating-detail" style=" position:absolute; background:white; padding: 20px 20px 8px 20px;visibility:hidden; outline: none!important;">
              <div v-for="comment_detail_item in comment.comment_detail" style="margin-bottom: 35px;">
                <p class="rating-star-detail-title" >{{comment_detail_item.title}}</p>
                <star-rating
                  :rating="comment_detail_item.grade"
                  :max-rating="5"
                  :star-size=5
                  :show-rating="false"
                  :increment="0.5"
                  inactive-color="#bfc8d2"
                  active-color="#ffbf00"
                  :read-only="true"/>
              </div>
            </div>
          </div>
          <p style="font-size: 12px; color: #5e6b79; margin-left: auto; margin-top: 4px">{{calculateDate(comment.created_at)}}</p>
        </div>
        <div style="padding-left: 60px; margin-top: -8px;margin-bottom:9px;">
          <p style="font-weight:400;word-break: break-word; width: 632px; font-size: 16px; line-height: 1.63;	color: #8b95a1; text-align: left; margin-top: -19px;"
             v-html="comment.comment"/>
        </div>
        <div v-if="comment.portfolio && comment.portfolio.uuid" style="margin-top: 20px; margin-left: 60px">
          <div @click="showPortfolioDetailModal(comment.portfolio)"  class="portfolio-container">
            <img :src="comment.portfolio.image_url_small" style="width: 90px; height: 50px; border-radius: 5px;"/>
          </div>
        </div>
        <div class="comment-reply-button"
             @click="openReplyTextArea(comment.comment_id)"
             v-if="isSelfUser && !comment.comment_reply && !answering[comment.comment_id] || (userProfile && userProfile.uuid === user.uuid && !comment.comment_reply && answering[comment.comment_id] && !answering[comment.comment_id].answering) ">
          <p>[cevapla]</p>
        </div>
        <div v-if="comment.comment_reply" class="comment-reply-container">
          <div class="comment-reply-user">
            <img class="comment-reply-user-image" :src="userProfile.avatar_url" alt="user-image">
          </div>
          <div style="display: flex;flex-direction: column;padding-left: 13px;width: 100%">
            <div style="display: flex;justify-content: space-between">
              <span class="comment-reply-user-name">
                {{ userProfile ? userProfile.username :'' }}
              </span>
            </div>
            <div style="display: flex">
              <span class="comment-reply-text" v-html="comment.comment_reply"/>
            </div>
          </div>
        </div>
        <div class="comment-reply-text-area" v-if="!comment.comment_reply  && answering[comment.comment_id] && answering[comment.comment_id].answering">
          <textarea v-model="answering[comment.comment_id].commentReply" maxlength="512" minlength="1"></textarea>
        </div>
        <div v-if="answering[comment.comment_id] && answering[comment.comment_id].answering" style="margin-top:8px;text-align: right; float: right; color: #8b95a1; font-size: 13px; font-weight: normal">
          {{ answering[comment.comment_id].commentReply.length }} / <span style="font-weight: bold;color: #2d3640;">250</span>
        </div>
        <div style="clear: both"></div>
        <div v-if="!comment.comment_reply && answering[comment.comment_id] && answering[comment.comment_id].answering" style="display: flex; float: right; ">
          <div class="comment-reply-cancel-button" @click="cancelCommenting(comment.comment_id)">
            <p>İptal</p>
          </div>
          <div :class="!buttonLoading && answering[comment.comment_id].commentReply ? 'comment-reply-save-button' : 'comment-reply-save-button-disabled'" @click="sendComment(comment)">
            <p>Gönder</p>
          </div>
        </div>
        <div style="clear: both"></div>
        <div class="separator-review" v-if="commentInfo.comments.length-1 !== index"/>
        <div v-else style="height: 30px"></div>
      </div>
    </div>
    <div v-if="commentInfo.commentCount <= 0 && !commentLoader" style="text-align: center; padding: 20px; font-size: 16px; width: 560px; color: #5e6b79; line-height: 1.75; margin: 56px auto">
      <template v-if="isSelfUser">
        <template v-if="userProfile.is_seller">
          Burası senden hizmet alanların aldıkları hizmetle ve seninle alakalı değerlendirmede bulundukları alan. Bu alanın yıldızlarla ve yorumlarla dolduğunu görmek için satış yapmaya başla. 😉
        </template>
        <template v-else>
          Burası hizmet satın aldığın freelancerların aldığın hizmet ve seninle alakalı değerlendirmede bulundukları alan. Bu alanın yıldızlarla ve yorumlarla dolduğunu görmek için hizmet satın almaya başla. 😉
        </template>
      </template>
      <template v-else>
       <div style="margin-top: -32px">Bu bölümde henüz bir değerlendirme yok.</div>
      </template>
    </div>
    <loader v-if="commentLoader" style="width: 25px; height: 25px; margin-top: 20px; margin-bottom: 20px;" />
    <div v-if="commentSeeMore" @click="getUserCommentsByUser(username, 10, commentOffset, commentType, true)" class="see-more-div">
      <loader v-if="commentSeeMoreLoader" style="width: 25px; height: 25px"/>
      <p v-else-if="!commentLoader">Daha fazla göster</p>
    </div>
  </div>
</template>

<script>
  import { fromNow } from '@/utils/dateFns'

  export default {
    name: "ReviewSection",
    props: {
      userProfile: {
        type: Object,
        default: () => ({})
      },
      initialCommentType: {
        type: String,
        default: () => ("buyer")
      }
    },
    data() {
      return {
        selectedCommentId: null,
        rerender:true,
        commentSeeMore: false,
        enableFreelancerReviews: false,
        username: null,
        commentInfo: {
          comments: [],
          commentGrades: [],
          commentGradesTemplate: [],
          commentRating: 0,
          commentCount: 0
        },
        commentType: "buyer",
        commentOffset: 0,
        commentSeeMoreLoader: false,
        commentLoader: false,
        answering: {},
        buttonLoading:false
      }
    },
    watch: {
      grades(newValue) {
        if (newValue && newValue.length) {
          this.$nextTick(() => this.updateStars())
        }
      },
      enableFreelancerReviews(newVal) {
        if(this.commentLoader) return false;
        const commentType = newVal ? 'seller' : 'buyer';
        this.commentType = commentType;
        this.commentOffset = 0;
        this.commentLoader = true;
        this.getUserCommentsByUser(this.username, 5, this.commentOffset, commentType, false)

      },
      '$store.state.routerParams': function (newVal) {
        if (newVal && newVal.length) {
          if(this.commentLoader) return false;
          this.commentOffset = 0;
          this.commentLoader = true;
          this.getUserCommentsByUser(this.username, 5, this.commentOffset, this.commentType, false)
        }
      }
    },
    computed: {
      grades() {
        if (this.isSelfUser) {
          return this.commentInfo.commentGrades.length ? this.commentInfo.commentGrades : this.commentInfo.commentGradesTemplate
        } else {
          return this.commentInfo.commentGrades
        }
      },
      isSelfUser() {
        return this.userProfile && this.user.username === this.userProfile.username
      }
    },
    created() {
      this.username = this.$store.state.routerParams[0];
      if(this.initialCommentType === 'seller') {
        this.commentType = 'seller';
        this.enableFreelancerReviews = true;
      } else {
        this.commentOffset = 0;
        this.getUserCommentsByUser(this.username, 5, this.commentOffset, this.commentType, false);

      }

    },
    methods: {
      hideCommentDetail(){
        if(this.selectedCommentId){
          const ele2 = document.getElementById('rating-star-'+this.selectedCommentId);
          if(ele2){
            ele2.style.visibility = 'hidden'
          }
          this.selectedCommentId = null;
        }

      },
      showCommentDetail(commentId){

        if(this.selectedCommentId && this.selectedCommentId === commentId){
          this.hideCommentDetail()
        } else {
          const ele = document.getElementById('rating-star-'+commentId);
          if(ele){
            if(this.selectedCommentId){
              const ele2 = document.getElementById('rating-star-'+this.selectedCommentId);
              if(ele2){
                ele2.style.visibility = 'hidden'
              }
            }
            ele.style.visibility = 'visible'
            this.$nextTick(() => this.updateStars())
            ele.focus()
            this.selectedCommentId = commentId;
          }
        }

      },
      updateStars(){
        let x = this.getSpansWithClass('vue-star-rating-star');
        let pointValue = "14.772500 0.500000, 10.310000 10.312500, 0.528750 11.203750, 0.316415 11.367075, 0.373750 11.628750, 7.782500 19.100000, 5.735000 29.441250, 5.836816 29.694906, 6.110000 29.703750, 14.967500 24.303750, 23.820000 29.706250, 24.092447 29.697558, 24.195000 29.445000, 22.207500 19.093750, 29.625000 11.628750, 29.682662 11.367579, 29.471250 11.203750, 19.685000 10.312500, 15.227500 0.500000, 15.000000 0.353652, 14.772500 0.500000"
        for (let i = 0; i < x.length; i++) {
          let ele = x[i].childNodes;
          ele[4].setAttribute('points', pointValue)
          ele[6].setAttribute('points', pointValue)
          ele[8].setAttribute('points', pointValue)
        }
      },
      getSpansWithClass(cssClass) {
        let elements = document.getElementsByTagName('svg');
        let out = [];
        for (let i = 0; i < elements.length; i++) {
          if((elements[i].className.baseVal) === cssClass) {
            elements[i].style.marginRight = '20px';
            elements[i].style.height = '0px';
            elements[i].style.width = '3px';
            elements[i].style.height = '3px';
            out.push(elements[i]);
          }
        }
        return out;
      },
      showPortfolioDetailModal(portfolio) {
        this.$store.state.showCase.data = portfolio;
        this.$store.commit(this.types.ACTIVE_MODAL, {modalType: this.Modals.PORTFOLIO, info: {uuid: portfolio.uuid}});
      },
      getUserCommentsByUser(username, limit, offset, type, comingFromSeemoreButton = false) {
        if (comingFromSeemoreButton) {
          this.commentSeeMoreLoader = true;
        } else {
          this.commentInfo.comments = [];
          this.rerender = false;
        }

        this.api.general.getUserProfileComments(username, limit, offset, type)
          .then(({data}) => {
            let result = data;
            if (result.success) {
              if (comingFromSeemoreButton) {
                this.commentInfo.comments = [...this.commentInfo.comments.concat(result.data.comments)];
              } else {
                this.commentInfo = result.data;
                this.commentInfo.comments = [...result.data.comments];
                this.commentInfo.commentGradesTemplate = result.data.commentGradesTemplate
              }

              this.rerender = true;
              this.commentOffset += limit;
              this.commentSeeMore = result.data.seemorediv;
            }
          })
          .catch(() => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          })
          .finally(() => {
            this.commentLoader = false;
            this.commentSeeMoreLoader = false;
          })
      },
      sendComment(comment) {
        if(!this.answering[comment.comment_id].commentReply) return false;
        if(this.buttonLoading) return false;
        this.buttonLoading = true;
        this.api.seller.replyComment(comment.comment_id, this.answering[comment.comment_id].commentReply)
          .then(({data}) => {
            let result = data;
            if (result.success) {
              this.answering[comment.comment_id].answering = false;
              comment.comment_reply = result.data.comment_reply;
              let newCommentInfo = Object.assign({}, this.commentInfo);
              this.commentInfo = newCommentInfo;
            } else {
              this.answering[comment.comment_id].commentReply = "";
              this.$toasted.global.errorToast({description: result.message});
            }
            this.buttonLoading = false
          })
          .catch(err => {
            this.answering[comment.comment_id].commentReply = "";
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
            this.buttonLoading = false
          });
      },
      openReplyTextArea(commentId) {
        let clone = Object.assign({}, this.answering);
        clone[commentId] = {};
        clone[commentId].answering = true;
        clone[commentId].commentReply = "";
        this.answering = clone;
      },
      cancelCommenting(commentId) {
        let clone = Object.assign({}, this.answering);
        clone[commentId].answering = false;
        clone[commentId].commentReply = "";
        this.answering = clone;
      },
      calculateDate(exDate) {
        return fromNow(exDate)
      }
    }
  }
</script>

<style lang="scss" scoped>



.rating-detail{
  margin-left:50px; margin-top:10px; visibility:hidden; position: absolute;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: -1px 13px 20px 0 #f0f2f4;
  z-index: 999;
  outline: none!important;

  &:focus{

    outline: none!important;

  }
  &:active{
    outline: none!important;

  }
  .rating-star-detail-title{
    font-size: 15px;
    color: #2d3640;
  }

}

  div.vue-star-rating{
   height: 0!important;
  }
  .seller-portfolio-wrapper{
    color: #2d3640;
    font-size: 18px;
    font-weight: 500;
    background-color: white;
    border: 1px solid #e8e8e8;
    margin-bottom: 0;
    .title {
      padding: 14px 30px;
      display: flex;
      justify-content: space-between;
      .option-wrapper{
        height: 52px;
        border-radius: 5px;
        border: solid 1px #eaedf2;
        background-color: rgba(244, 245, 247, 0.2);
        font-size: 13px;
        font-weight: 500;
        color: #5e6b79;
        .option {
          width: 166px;
          height: 32px;
          line-height: 31px;
          cursor: pointer;
          text-align: center;
          letter-spacing: -0.2px;
          font-weight: 500;
          font-size: 13px;
          color: #5e6b79;
        }
        .active {
          background-color: #fd4056;
          color: #ffffff;
        }
      }
    }
    .rates {
      width: 100%;
      height: 130px;
      display: flex;
      background-color: rgba(244, 245, 247, 0.5);
      border-top: solid 1px #eaedf2;
      border-bottom: solid 1px #eaedf2;
      .symbols{
        width: calc(100% - 187px);
        .wrapper{
          margin: 30px 50px;
          display: flex;
          .box{
            &:nth-child(n+2) {
              margin-left: 50px;
            }
            .review-title {
              font-size: 14px;
              color: #2d3640;
            }
            .review-icon /deep/{
              width: 24px;
              height: 24px;
              path {
                fill: #8b95a1
              }
            }
          }
        }
      }
      .total-reviews {
        width: 187px;
        background-color: #ffffff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
    .review-comments {
      padding: 20px 30px 0;
    }
    .portfolio-img {
      cursor: pointer;
      &:nth-child(2n) {
        margin-left: 41px;
      }
    }
  }

  .separator-review {
    width: 100%;
    height: 0;
    padding: 0 30px;
    margin: 30px 0 30px -30px;
    border-radius: 5px;
    border-top: solid 1px rgba(234, 237, 242, 0.5);
    background-color: #ffffff;
  }

  .user-info-rating-div {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .star-rating /deep/{
    width: 18px;
    height: 18px;
    path {
      fill: #ffbf00
    }
  }

  .rating-number {
    font-weight: bold;
    font-size: 16px;
    color: #ffbe00;
    margin-left: 5px;
  }

  .see-more-div {
    display: flex;
    align-items: center;
    height: 68px;
    justify-content: center;
    border-top: solid 1px #eaedf2;
  }

  .see-more-div p {
    font-size: 14px;
    cursor: pointer;
    font-weight: 600;
    color: #2d3640;
    vertical-align: middle;
    line-height: 60px;
    text-align: center;
  }



  .comment-reply-button {
    margin: 20px 0 30px 60px;
    cursor: pointer;
    p {
      font-size: 16px;
      font-weight: 500;
      text-align: left;
      color: #5e6b79;
      &:hover{
        text-decoration: underline;
        color: #2d3740;
      }
    }
  }



  .comment-reply-save-button {
    cursor: pointer;
    margin-top: 10px;
    width: 95px;
    height: 44px;
    border-radius: 2px;
    border: solid 1px #00a575;
    color: #fff;
    background-color:#00a575;
    &:hover {
      color: #ffffff;
      background-color: #00a575;
      border: solid 1px #00a575;
      opacity: 0.95;
    }
    p {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-weight: 600;
      text-align: center;
      height: 42px;
    }
  }

  .comment-reply-cancel-button {
    cursor: pointer;
    margin-top: 10px;
    margin-right: 25px;
    width: 95px;
    height: 44px;
    border-radius: 2px;
    border: solid 1px #bfc8d2;
    color: #2d3640;
    &:hover {
      color: #ffffff;
      background-color: #bfc8d2;
      border: solid 1px #bfc8d2;
    }
    p {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-weight: 600;
      text-align: center;
      height: 42px;
    }
  }


  .comment-reply-save-button-disabled {
    margin-top: 10px;
    width: 95px;
    height: 44px;
    border-radius: 2px;
    border: solid 1px #bfc8d2;
    color: #fff;
    background-color: #bfc8d2;
    &:hover {
      color: #ffffff;
      background-color: #bfc8d2;
      border: solid 1px #bfc8d2;
    }
    p {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-weight: 600;
      text-align: center;
      height: 42px;
    }
  }


  .comment-reply-container {
    padding: 20px 20px 0 0;
    display: flex;
    margin-left: 60px;
    align-items: flex-start;
  }

  .comment-reply-text-area {
    margin-left: 60px;
    margin-bottom: 20px;
    margin-top: 20px;
    width: calc(100% - 56px);
    height: 80px;
    display: flex;
    align-items: center;

    img {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      margin-right: 10px;
    }
    textarea {
      width: 100%;
      margin-top: 23px;
      border-radius: 2px;
      border: solid 1px rgba(75, 79, 82, 0.2);
      background-color: #eff3f8;
      padding: 20px;
      opacity: 0.5;
      font-size: 16px;
      font-weight: 300;
      line-height: 1.5;
      color: #2d3640;
      &:focus{
        opacity: 1;
        background-color: #fff;
      }
    }
  }

  .comment-reply-user {
    display: flex;
    align-items: center;
  }

  .comment-reply-user-image {
    width: 34px;
    height: 34px;
    border-radius: 50%;
    border:1px solid #bfc8d2;
  }

  .comment-reply-user-name {
    font-size: 16px;
    font-weight: 500;
    color: #4b4f52;
  }

  .comment-reply-text {
    padding-top: 9px;
    font-size: 16px;
    line-height: 1.63;
    color: #8b95a1;
    font-weight:400;
  }

  .comment-reply-date-text {
    font-size: 14px;
    color: #939c9c
  }


  .portfolio-container{
    width: 90px;
    height: 50px;
    border-radius: 5px;
    box-shadow: 0 0 10px 0 rgba(45, 54, 64, 0.05);
    border: solid 1px #eaedf2;
    background-color: #ffffff;
    cursor: pointer;

  }
</style>
