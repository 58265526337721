<template>
  <div v-if="!isPageLoaded">
    <div class="information-section-1">
      <div style="display: flex; flex-direction: column;align-items: center">
        <div class="information-section-1-loader-avatar profile-loader"></div>
        <div class="information-section-1-loader-username profile-loader"></div>
        <div class="information-section-1-loader-title profile-loader"></div>
        <div class="information-section-1-loader-stars profile-loader"></div>
        <div class="followers-div-loader profile-loader"></div>
      </div>
      <div class="hiddendiv20"></div>
      <div class="hiddendiv20"></div>
      <div class="line"></div>
      <div class="hiddendiv20"></div>
      <div class="hiddendiv20"></div>
      <div style="display: flex; justify-content: space-between;">
        <div>
          <div class="general-info-icon-loader profile-loader" style="width: 180px"></div>
          <div class="general-info-icon-loader profile-loader"  style="width: 100px"></div>
          <div class="general-info-icon-loader profile-loader"></div>
        </div>
        <div style="display: flex; flex-direction: column; align-items: flex-end">
          <div class="general-info-loader profile-loader" ></div>
          <div class="general-info-loader profile-loader" style="width: 60px"></div>
          <div class="general-info-loader profile-loader" style="width: 90px"></div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <div style="position: relative">
      <div v-if="isSelfUser" style="position: absolute; right: 0; top: -18px; width: 52px">
        <router-link to="/settings?section=1&open=1" style="color: #00a575; font-size: 14px;">Düzenle</router-link>
      </div>
      <div v-else-if="userProfile && userProfile.is_seller" style=" left: 0; margin-top: -24px; margin-bottom: 24px; text-align: left">
        <div class="add-list-area">
          <div v-show="followStatus.action !== -1" class="add-list-icon-container" @click="addListOpen = true">
            <img class="add-list-icon" src="https://gcdn.bionluk.com/uploads/message/00f6fa53-091c-4f3e-a086-2c499c2f7fce.svg" onload="SVGInject(this)"/>
          </div>
          <div v-show="followStatus.action === -1" class="add-list-icon-container" @click="addListOpen = true">
            <img class="add-list-icon-red" src="https://gcdn.bionluk.com/uploads/message/205977ee-c82d-426d-93f7-ec98970c9fe3.svg" onload="SVGInject(this)">
          </div>
          <p v-if="followStatus.action !== -1" @click="addListOpen = true" class="add-list-text">Listeye Ekle</p>
          <p v-else-if="followStatus.action === -1" @click="addListOpen = true" class="add-list-text-red">Listemde</p>

          <p v-if="userProfile && userProfile.likeCount" class="xbubble">
              <span style="cursor: help" :data-balloon="userProfile.likeCount +' kişi listesine ekledi.'"
                    data-balloon-pos="down">{{ userProfile.likeCount | number_format }}</span>
          </p>


          <div  v-click-away="() => addListOpen = false" v-if="addListOpen" class="setting-box">
            <loader style="width: 30px; height: 30px" v-if="buttonLoading"/>
            <template v-else>
              <div class="setting-box-options-overflow">
                <div class="setting-box-options" v-for="(liste, lin) in lists" :key="lin" :style="lin + 1 !== lists.length ? ' border-bottom: solid 1px #eaedf2;' : ''">
                  <custom-check
                    :loading="checkLoading"
                    called-from="singlegig"
                    :label="liste.title"
                    :single-gig="liste.counts"
                    :id="'list_'+userProfile.uuid+'_'+liste.id"
                    :checked="liste.liked"
                    :value="liste.liked"
                    @input="likeChecked(liste,lin)"
                  />



                </div>


                <div  class="settings-box-add-new mt-3" @click="openAddListModal">
                  <div class="ml-2">Yeni Liste Oluştur</div>
                  <div class="rounded-plus-button-gray">

                    <img class="icon" src="https://storage.googleapis.com/superlancer.appspot.com/public/ic-plus.svg" onload="SVGInject(this)">
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>

      </div>
      <div style="display: inline-flex; margin-top: 10px">
        <div class="avatar-wrapper">
          <div class="status-icon" :style="{backgroundColor: userProfile.last_seen.text === 'Çevrimiçi' ? '#5beb17' : '#8b95a1'}"/>
          <img class="avatar" :src="userProfile.avatar_url"/>
        </div>
      </div>
      <h1 class="username">{{ userProfile.username }}</h1>
      <div class="userTitle">{{ userProfile.title }}</div>
      <div v-if="userProfile.sellerRating.commentCount > 0" class="user-info-rating-div">
        <img src="https://gcdn.bionluk.com/site/cicons/ic-star.svg" onload="SVGInject(this)" class="star-rating"/>

        <p class="rating-number">{{ userProfile.sellerRating.commentRating | convertNumberToFraction }}
          <span class="comment-count"> ({{ userProfile.sellerRating.commentCount }})</span>
        </p>
      </div>
      <div v-if="isSelfUser" style="display: flex; align-items: center; justify-content: center; margin-top: 30px">
        <div
          :data-balloon="'Daha önce takip ettiğin kullanıcıları artık Listelerim üzerinden inceleyebilirsin'" data-balloon-pos="up"
          @click="$router.push('/panel/listelerim')" class="followers-count">
          <div class="inner-text">Listelerime Git</div>
        </div>
      </div>
    </div>
    <button class="send-message-button" :disabled="buttonLoading" v-if="!isSelfUser" @click="sendMessage()">
      Mesaj Gönder
    </button>
    <div v-if="!isSelfUser && userProfile.is_seller" class="or-wrapper">
      <div class="dash"/>
      <div style="height: 16px">veya</div>
      <div class="dash"/>
    </div>
    <div v-if="!isSelfUser && userProfile.is_seller" class="freelancer-line-wrapper">
      <div class="info">
        <div class="text-wrapper" style="line-height: 1.71;">
          <img src="https://gcdn.bionluk.com/site/cicons/freelancer-line.svg" style="width: 20px; height: 20px; margin-top: 2px"/>
          <div v-if="userProfile.f_t_info.isActive" style="margin-left: 8px; color: #8b95a1; font-size: 14px; font-weight: normal">
            <span style="color: #00a575; font-weight: 500">Freelancer Telefon Hattı</span> üzerinden ulaş.
          </div>
          <div v-else style="margin-left: 8px; color: #8b95a1; font-size: 14px; font-weight: normal;">
            <span style="color: #00a575; font-weight: bold">Bionluk Freelancer Hattı</span>
            üzerinden ulaşmak için freelancer’dan hattını aktif etmesini iste.
          </div>
        </div>
        <div v-if="userProfile.f_t_info.isActive" class="question-mark-wrapper" style="margin-top: 3px;" :data-balloon="reachLineText" data-balloon-pos="left" data-balloon-length="medium">
          <img src="https://gcdn.bionluk.com/site/cicons/ic-what-is-it.svg" onload="SVGInject(this)" class="question-mark-icon"/>
        </div>
      </div>
      <div  v-if="userProfile.f_t_info.isActive" style="margin-left: 27px; margin-top: 26px;">
        <div style="display: flex; justify-content: space-between">
          <span class="info-label">Ulaşılacak Telefon</span>
          <span style="color: #2d3640; font-weight: bold; font-size: 16px">0 (212) 909 - 23 - 10</span>
        </div>
        <div style="margin-top: 16px; display: flex; justify-content: space-between">
          <span class="info-label">Dahili Numarası</span>
          <span v-if="!isLoggedIn" style="font-weight: bold; font-size: 12px; color: #2d3640; margin-top: 2px">
            <span>Görmek için</span>
            <router-link to="/login" style="color: #fd4056;">üye girişi yapmalısın.</router-link>
          </span>
          <div v-else style="display:flex;color: #2d3640; font-weight: bold; font-size: 16px;">{{ userProfile.f_t_info.isPublic ? userProfile.f_t_info.line : 'Gizli ' }}

            <div v-if="!userProfile.f_t_info.isPublic" class="question-mark-wrapper" style="margin-left: 5px;" data-balloon="Freelancer dahili numarasını gizlemeyi tercih ediyor. Kendisine mesaj göndererek telefonla görüşme talebini iletebilirsin." data-balloon-pos="left" data-balloon-length="medium">
              <img src="https://gcdn.bionluk.com/site/cicons/ic-what-is-it.svg" onload="SVGInject(this)" class="question-mark-icon"/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="userProfile.is_seller" class="freelancer-line-wrapper">
      <div class="info">
        <div class="text-wrapper" style="line-height: 1.71;">
          <img src="https://gcdn.bionluk.com/site/cicons/freelancer-line.svg" style="width: 18px; height: 18px; margin-top: 2px"/>
          <div style="margin-left: 8px; color: #8b95a1; font-size: 14px; font-weight: normal">
            <span style="color: #00a575; font-weight: 500">Freelancer Telefon Hattı</span>
          </div>
        </div>
        <div v-show="!user.info.gsm || !user.info.gsm_is_activated" class="question-mark-wrapper" @click="openFreelancerLineFaqModal()">
          <img src="https://gcdn.bionluk.com/site/cicons/ic-what-is-it.svg" onload="SVGInject(this)" class="question-mark-icon"/>
        </div>
        <div v-show="user.info.gsm_is_activated" class="settings-wrapper" @click="$router.push('/settings?section=2&open=1')">
          <img src="https://gcdn.bionluk.com/site/cicons/ic-settings.svg" onload="SVGInject(this)" class="icon"/>
        </div>
      </div>
      <template v-if="!user.info.gsm || !user.info.gsm_is_activated">
        <div style="margin-top: 15px; margin-left: 26px; width: 328px; color: #8b95a1; font-size: 14px; font-weight: normal; line-height: 1.71;">
          İlk 1 ay ücretsiz freelancer hattını kullanabilmek için öncelikle cep telefonunu <span style="font-style: italic; color: #fd4056;">eklemen ve onaylaman</span> gerekiyor.
        </div>
        <div class="add-phone-button" style="margin: 30px auto 0">
          <div v-if="!user.info.gsm" class="text" @click="$router.push('/settings?section=2&open=1')">
            Cep Telefonunu Ekle
          </div>
          <div v-else class="text" @click="sendVerificationNumber()">
            Telefonunu Onayla
          </div>

        </div>
      </template>
      <template v-else-if="user.info.f_t_used">
        <div style="display: flex; justify-content: space-between; height: 28px; margin-top: 15px">
          <div style="color: #8b95a1; font-size: 14px; font-weight: 300; line-height: 28px">Hat Durumu</div>
          <div class="line-status-button" v-if="user.info.f_t_status === 4">Aktif Değil [Süresi Doldu]</div>
          <div class="line-status-button" v-if="user.info.f_t_status === 2" style="width: 146px">Aktif Değil</div>
          <div class="line-status-button" style="background-color: #26ca9a; width: 146px" v-if="user.info.f_t_status === 3">Aktif [{{ endingDateDiff }} gün kaldı]</div>
        </div>
        <div style="display: flex; justify-content: space-between; margin-top: 20px">
          <div style="color: #8b95a1; font-size: 14px; font-weight: 300;">Ulaşılacak Telefon</div>
          <div style="color: #2d3640; font-weight: bold; font-size: 16px">0 (212) 909 - 23 - 10</div>
        </div>
        <div style="display: flex; justify-content: space-between; margin-top: 20px">
          <div style="color: #8b95a1; font-size: 14px; font-weight: 300;">Dahili {{isSelfUser ? 'Numaran' : 'Numarası'}}</div>
          <div style="color: #2d3640; font-weight: bold; font-size: 16px">{{ user.info.f_t_line }} <span style="font-weight: 500; color: #5e6b79; font-size: 14px" v-if="user.info.f_t_mode === 2">[gizli]</span></div>
        </div>
        <div style="display: flex; justify-content: space-between; margin-top: 20px">
          <div style="color: #8b95a1; font-size: 14px; font-weight: 300;">Geçerlilik Tarihi</div>
          <div style="font-size: 16px; font-weight: bold; color: #2d3640;">
            <div v-if="user.info.f_t_status !== 4"> <span v-if="endingDate" style="margin-bottom: 16px">{{ endingDate }}</span></div>
            <div style="font-weight: normal; color: #fd4056; font-size: 14px; text-align: right; cursor: pointer" @click="extend('profile')">[Süreyi Uzat]</div>
          </div>
        </div>
      </template>
      <template v-else-if="!user.info.f_t_used">
        <div style="line-height: 1.71; font-size: 14px; width: 350px; color: #8b95a1; margin-top: 13px">
          <span style="font-weight: bold; color: #2d3640;">Bionluk Freelancer Hattı</span> sayesinde kişisel numaranı paylaşmaksızın cep telefonun üzerinden tüm Bionluk kullanıcılarıyla güvenle görüşmeye başlayabilirsin. <span style="color: #00a575; font-weight: bold">Üstelik ilk ay ücretsiz!</span> 😉
        </div>
        <div  style="margin: 30px auto 0; display: flex; justify-content: center">
          <button :disabled="buttonLoading" class="add-phone-button">
            <div class="text" @click="startLine()">
              Freelancer Hattını Aç
            </div>
          </button>
        </div>
      </template>
    </div>
    <div class="line" style="margin-top: 30px"/>
    <div v-if="userProfile.is_seller && userProfile.isBuyer" style="margin-top: 30px">
      <div class="option-wrapper">
        <div style="margin: 10px; display: flex">
          <div class="option" :class="enabledFreelancerStatistics ? 'active' : null" @click="enabledFreelancerStatistics = true">Freelancer İstatistikleri</div>
          <div class="option" :class="!enabledFreelancerStatistics ? 'active' : null" @click="enabledFreelancerStatistics = false">Alıcı İstatistikleri</div>
        </div>
      </div>
    </div>
    <div style="margin-top: 30px;">
      <div v-if="enabledFreelancerStatistics && userProfile.recurring_orders" style="color: #8b95a1; font-size:14px; width: 100%">
        <div  style="display: flex; flex-direction: row; align-items: center; justify-content: space-between;">
          <div style="display: flex">
            <img src="https://gcdn.bionluk.com/site/cicons/ic-change_oval3.svg" onload="SVGInject(this)" class="info-icons">
            <div style="padding-left: 30px;" class="info-label">Tekrarlayan Sipariş Oranı</div>
          </div>
          <div style="display: flex; justify-content: flex-end">
            <div style="color: #2d3640; font-size: 16px; font-weight: 500; margin-right: 5px">
              {{userProfile.recurring_orders}} sipariş
            </div>
            <span class="question-mark-wrapper" :data-balloon="repetitiveText" data-balloon-pos="left" data-balloon-length="medium">
                <p style="text-align: right;">
                  <img src="https://gcdn.bionluk.com/site/cicons/ic-what-is-it.svg" onload="SVGInject(this)" class="question-mark-icon"/>
                </p>
              </span>
          </div>
        </div>
      </div>
      <template v-if="enabledFreelancerStatistics">
        <template>
          <div v-if="!userProfile.is_seller" v-for="info in userProfile.buyerInfo" style="margin-top: 30px; color: #8b95a1; font-size:14px; width: 100%">
            <div v-if="info.value" style="display: flex; flex-direction: row; align-items: center; justify-content: space-between;">
              <div>
                <img :src="info.icon_svg" onload="SVGInject(this)" class="info-icons">
                <span style="padding-left: 30px;" class="info-label">{{ info.name }}</span>
              </div>
              <p style=" color: #2d3640; font-size: 16px; font-weight:500;" :style="info.value === 'Çevrimiçi' ? 'color: #5beb17' : null">
                {{ convertTime(info) }}
              </p>
            </div>
          </div>
          <div v-for="info in userProfile.generalInfo" style="margin-top: 30px; color: #8b95a1; font-size:14px; width: 100%">
            <div v-if="info.value" style="display: flex; flex-direction: row; align-items: center; justify-content: space-between;">
              <div style="display: flex">
                <img :src="info.icon_svg" onload="SVGInject(this)" class="info-icons">
                <div style="padding-left: 30px;" class="info-label">{{ info.name }}</div>
              </div>
              <p style=" color: #2d3640; font-size: 16px; font-weight:500;" :style="info.value === 'Çevrimiçi' ? 'color: #5beb17' : null">
                {{ convertTime(info) }}
              </p>
            </div>
          </div>
        </template>
      </template>
      <template v-else-if="userProfile.isBuyer">
        <div v-for="info in userProfile.buyerInfo" style="margin-top: 30px; color: #8b95a1; font-size:14px; width: 100%">
          <div v-if="info.value" style="display: flex; flex-direction: row; align-items: center; justify-content: space-between;">
            <div>
              <img :src="info.icon_svg" onload="SVGInject(this)" class="info-icons">
              <span style="padding-left: 30px;" class="info-label">{{ info.name }}</span>
            </div>
            <p style=" color: #2d3640; font-size: 16px; font-weight:500;" :style="info.value === 'Çevrimiçi' ? 'color: #5beb17' : null">
              {{ convertTime(info) }}
            </p>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import CustomCheck from "@/pages/components/CustomCheck"
import { customDateFormat, diffFromNow }  from '@/utils/dateFns'
  export default {
    name: "ProfileInfo",
    components:{CustomCheck},
    props: {
      userProfile: {
        type: Object,
        default: () => {}
      },
      isPageLoaded: {
        type: Boolean,
        default: () => false
      },
      initialFollowStatus: {
        type: Object,
        default: () => ({
          action: null,
          actionClass: null,
          actionText: null
        })
      },
      adminoLink: {
        default: () => {}
      }
    },
    data() {
      return {
        singlePayEventBusOn:false,
        disableVerify: false,
        enabledFreelancerStatistics: true,
        buttonLoading:false,
        disableFollowButton: false,
        repetitiveText: "Bu sayı daha önce sipariş vermiş kullanıcıların, sonraki farklı günlerde tekrar verdikleri sipariş sayısını belirtir. Müşteri memnuniyeti ve hizmet kalitesinin en önemli göstergelerinden biridir.",
        reachLineText: "Yanda görülen telefon numarası ve dahili numara ile ilgili freelancer'a telefonla ulaşabilirsin. Operatörünün yurt içi sabit hat tarifesinden ücretlendirilirsin. Bu hattın ekstra bir ücreti yoktur.",
        followStatus: {
          action: null,
          actionClass: null,
          actionText: null
        },

        addListOpen:false,
        checkLoading:false,
        lists:[],
      }
    },
    watch: {
      addListOpen:function (newVal, oldVal){
        if(newVal){
          if (!this.isLoggedIn) {
            this.$router.push('/login');
          } else {
            this.getLists()
          }
        }
      },
      initialFollowStatus(newFollowStatus) {
        this.setInitialFollowStatus(newFollowStatus)
      },
      '$store.state.routerParams': function (newVal) {
        if (newVal && newVal.length) {
         this.enabledFreelancerStatistics = true;

         let profileUsername = newVal[0];
         if(this.user && this.user.username && this.user.username === profileUsername){
           if(!this.singlePayEventBusOn){
             this.singlePayEventBusOn = true;
             this.EventBus.$on('isSinglePayed', (payload) => {
               if(payload.isSinglePayed) {

                 this.api.user.extendLine(this.$Progress)
                   .then(({data}) => {
                     let result = data;

                     if (result.success) {
                       this.$store.state.user.info.f_t_ending_at = result.data.f_t_ending_at;
                       this.$store.state.user.info.f_t_ending_at_timestamp = result.data.f_t_ending_at_timestamp;
                       this.$store.state.user.info.f_t_status = 3;
                       this.$toasted.global.errorToast({description: result.message});

                     } else {
                       this.$toasted.global.errorToast({description: result.message});
                     }

                   });

               } else {
                 this.$toasted.global.errorToast({description: 'Ödeme Başarısız Oldu'});
               }
             });
           }
         } else {
           if(this.singlePayEventBusOn){
             this.EventBus.$off('isSinglePayed');
             this.singlePayEventBusOn = false;
           }
         }
        } else {
          if(this.singlePayEventBusOn){
            this.EventBus.$off('isSinglePayed');
            this.singlePayEventBusOn = false;
          }
        }
      }
    },
    beforeDestroy() {
      this.EventBus.$off('isSinglePayed');
    },
    computed: {
      endingDateDiff() {
        const endingTimeStamp = this.$store.state.user.info.f_t_ending_at_timestamp
        return endingTimeStamp ? diffFromNow(endingTimeStamp, 'days') : null
      },
      endingDate() {
        const endingTimeStamp = this.$store.state.user.info.f_t_ending_at_timestamp
        return endingTimeStamp ? customDateFormat(endingTimeStamp, 'dd MMMM yyyy HH:mm') : null
      },
      isSelfUser() {
        return this.userProfile ? this.user.username === this.userProfile.username : false
      }
    },
    methods: {
      getLists(){
        if(this.buttonLoading) return false;
        this.buttonLoading = true;
        this.api.general.crudList('get_all', 'freelancer', null, this.userProfile.user_id, this.$Progress)
          .then(({data}) => {
            let result = data;
            this.lists = result.data.lists
            this.buttonLoading = false;
          })
          .catch(err => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
            this.buttonLoading = false;
          })

      },
      openAddListModal(){
        this.addListOpen = false;
        let data = {
          type: 'create',

        };
        this.$store.commit(this.types.ACTIVE_MODAL, {modalType: this.Modals.ADD_LIST, info: {data: data}});

      },

      likeChecked(l,index){
        if(l.liked){
          this.lists[index].liked = false;
          this.lists[index].counts.freelancer--;
          this.removeFromList(l.id);
          this.followStatus = {action:1}
          this.lists.forEach((ll) => {
            if(ll.liked){
              this.followStatus = {action:-1}
            }
          })
        } else {
          this.lists[index].liked = true;
          this.lists[index].counts.freelancer++;
          this.addToList(l.id);
          this.followStatus = {action:-1}
        }
      },
      addToList(listId){
        if(this.checkLoading) return false;

        this.checkLoading = true;
        let page = this.$store.state.componentMap.page;
        this.api.general.crudList('like_item', 'freelancer', listId, this.userProfile.user_id, this.$Progress, 0, page)
          .then(({data}) => {
            let result = data;
            if(result.success){
              this.checkLoading = false;
              this.$toasted.global.infoToast({description: result.message});
            } else {
              this.checkLoading = false;
              this.$toasted.global.errorToast({description: result.message});
            }
          })
          .catch(err => {
            this.checkLoading = false;
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          })
      },
      removeFromList(listId){
        if(this.checkLoading) return false;

        this.checkLoading = true;
        this.api.general.crudList('delete_item', 'freelancer', listId, this.userProfile.user_id, this.$Progress)
          .then(({data}) => {
            let result = data;
            if(result.success){
              this.checkLoading = false;
              this.$toasted.global.errorToast({description: result.message});
            } else {
              this.checkLoading = false;
              this.$toasted.global.errorToast({description: result.message});
            }
          })
          .catch(err => {
            this.checkLoading = false;
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          })
      },

      startLine() {
        this.buttonLoading = true;
        this.api.user.startLine(this.user.info.f_t_used, this.$Progress)
          .then(({data}) => {
            let result = data;

            if (result.success) {
              this.$store.state.user.info.f_t_status = result.data.f_t_status;
              this.$store.state.user.info.f_t_used = true;
              this.$store.state.user.info.f_t_ending_at = result.data.f_t_ending_at;
              this.$store.state.user.info.f_t_ending_at_timestamp = result.data.f_t_ending_at_timestamp;
              this.$store.state.user.info.f_t_mode = result.data.f_t_mode;
              this.$store.state.user.info.f_t_line = result.data.f_t_line;
              this.$toasted.global.infoToast({description: result.message});
            } else {
              this.$toasted.global.errorToast({description: result.message});
            }
            this.buttonLoading = false;

          })
          .catch(err => {
            console.log(err);
            this.buttonLoading = false;
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          });

      },
      sendVerificationNumber() {
        this.disableVerify = true;
        this.api.user.activateGSM(this.user.info.gsm.replace(/ /g, ""), this.$Progress)
          .then(({data}) => {
            let result = data;

            if (result.success) {
              this.$store.commit(this.types.ACTIVE_MODAL, {modalType: this.Modals.PHONE_VERIFICATION, info: {gsm: this.user.info.gsm.replace(/ /g, "")}});
            } else {
              this.$toasted.global.errorToast({description: result.message})
            }
            this.disableVerify = false;
          })
          .catch(err => {
            console.log(err);
            this.disableVerify = false;
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          });
      },
      openFreelancerLineFaqModal() {
        this.$store.commit(this.types.ACTIVE_MODAL, {modalType: this.Modals.FREELANCER_LING_FAQ});
      },
      extend(clickedFrom = null) {
        this.$store.state.clickedFrom = clickedFrom;
        this.api.user.useItem(2, this.$Progress)
          .then(({data}) => {
            let result = data;
            if (result.success) {
              this.api.user.extendLine(this.$Progress)
                .then(({data}) => {
                  let result = data;

                  if (result.success) {
                    this.$store.state.user.info.f_t_ending_at = result.data.f_t_ending_at;
                    this.$store.state.user.info.f_t_ending_at_timestamp = result.data.f_t_ending_at_timestamp;
                    this.$store.state.user.info.f_t_status = 3;
                    this.$toasted.global.errorToast({description: result.message});
                  } else {
                    this.$toasted.global.errorToast({description: result.message});
                  }
                });
            } else {
              if (result.data && result.data.ok && result.data.ok === 'wait') {
                this.$toasted.global.errorToast({description: result.message});
              } else if (result.data && result.data.cards) {
                const data = {
                  data: {
                    type: "item",
                    total: 12.90,
                    itemId: 2,
                    cards: result.data.cards,
                    walletAmount:result.data.walletAmount
                  }
                };
                this.$store.state.clickedFrom = clickedFrom;
                this.$store.commit(this.types.ACTIVE_MODAL, {modalType: this.Modals.CHECKOUT, info: {checkOutData: data, isSinglePay: true}});
              } else {
                this.$toasted.global.errorToast({description: result.message});
              }
            }
          });
      },
      setInitialFollowStatus(followStatus) {
        this.followStatus.action = followStatus.action
        this.followStatus.actionClass = followStatus.actionClass
        this.followStatus.actionText = followStatus.actionText
      },
      sendMessage() {
        this.$store.state.clickedFrom = 'below avatar';
        if (!this.isLoggedIn) {
          this.$router.push('/login/');
        } else {
          this.buttonLoading = true;
          this.api.workstation.chatOpen(this.userProfile.username, 0,1)
            .then(({data}) => {
              let result = data;
              if (result.success) {
                let conversationSendData = {
                  r_username: this.userProfile.username,
                  r_avatar_url: this.userProfile.avatar_url,
                  r_id: this.userProfile.user_id,
                  r_uuid: this.userProfile.uuid,
                  c_uuid: result.data.c_uuid,
                  fromWhere: 'User Profile',
                };
                this.EventBus.$emit('externalOpenChat', conversationSendData);
              } else {
                this.$toasted.global.errorToast({description: result.message});
              }
              this.buttonLoading = false;
            })
            .catch(() => {
              this.buttonLoading = false;
              this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
            });
        }
      },
      convertTime(info) {
        if (info.name === "Ort. Yanıt Süresi") {
          let timeStamp = info.value;
          let years = timeStamp / 1000 / 60 / 60 / 24 / 7 / 30 / 12;
          if (years >= 1) {
            return years.toFixed(0) + " yıl"
          } else {
            let months = timeStamp / 1000 / 60 / 60 / 24 / 7 / 30;
            if (months >= 1) {
              return months.toFixed(0) + " ay";
            } else {
              let weeks = timeStamp / 1000 / 60 / 60 / 24 / 7;
              if (weeks >= 1) {
                return weeks.toFixed(0) + " hafta";
              } else {
                let days = timeStamp / 1000 / 60 / 60 / 24;
                if (days >= 1) {
                  return days.toFixed(0) + " gün";
                } else {
                  let hours = timeStamp / 1000 / 60 / 60;
                  if (hours >= 1) {
                    return hours.toFixed(0) + " saat"
                  } else {
                    let minutes = timeStamp / 1000 / 60;
                    if (minutes >= 1) {
                      return minutes.toFixed(0) + " dakika";
                    } else {
                      let seconds = timeStamp / 1000;
                      return (seconds >= 1 ? seconds.toFixed(0) : 0) + " saniye";
                    }
                  }
                }
              }
            }
          }
        } else {
          return info.value;
        }
      }
    },
    created() {
      this.setInitialFollowStatus(this.initialFollowStatus);

      let profileUsername = this.$store.state.routerParams[0];
      if(!this.singlePayEventBusOn && this.user.username && this.user.username === profileUsername){
        this.singlePayEventBusOn = true;
        this.EventBus.$on('isSinglePayed', (payload) => {
          if(payload.isSinglePayed) {

            this.api.user.extendLine(this.$Progress)
              .then(({data}) => {
                let result = data;

                if (result.success) {
                  this.$store.state.user.info.f_t_ending_at = result.data.f_t_ending_at;
                  this.$store.state.user.info.f_t_ending_at_timestamp = result.data.f_t_ending_at_timestamp;
                  this.$store.state.user.info.f_t_status = 3;
                  this.$toasted.global.errorToast({description: result.message});

                } else {
                  this.$toasted.global.errorToast({description: result.message});
                }

              });

          } else {
            this.$toasted.global.errorToast({description: 'Ödeme Başarısız Oldu'});
          }
        });
      }
    }
  }
</script>

<style lang="scss" scoped>

.xbubble {
  font-size: 13px;
  color: #5e6b79;
  font-weight: 500;

  padding:4px 6px 5px 6px;
  display: flex;
  align-items: center;
  justify-content: center;


  margin-left: 10px;
  position: relative;
  border: 1px solid #bfc8d2;
  border-radius: 3px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.xbubble:before {
  z-index: 2;
  border-width: 6px 6px 6px 0;
  border-color: transparent #bfc8d2 transparent transparent;
  top: 6px;
  left: -6px;
  content: "";
  position: absolute;
  border-style: solid;
}

.xbubble:after{
  z-index: 3;
  border-width: 4px 7px 4px 0;
  border-color: transparent #fff transparent transparent;
  top: 8px;
  left: -6px;
  content: "";
  position: absolute;
  border-style: solid;
}

.rounded-plus-button{
  margin: 0 auto;
  width: 40px;
  height: 40px;
  cursor: pointer;
  background-image: url(https://storage.googleapis.com/superlancer.appspot.com/public/rounded-border-bg.svg);
  background-size: 40px 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  .icon /deep/ {
    height:14px;
    width: 14px;
    path {
      fill: #65c4db
    }
  }
}

.rounded-plus-button-gray{
  width: 24px;
  height: 24px;
  background: #eff3f8;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 10px;
  .icon /deep/ {
    height:11px;
    width: 11px;
    path {
      fill: #8b95a1
    }
  }
}

.setting-box {
  position: absolute;
  width: 240px;
  border-radius: 10px;
  background-color: #ffffff;
  padding: 10px 0 20px 20px;


  //    border: solid 1px #bfc8d2;
  left: -15px;
  top: 30px;
  z-index: 99910;
  box-shadow: 0 1px 10px 0 rgba(45, 54, 64, 0.2);
  border: solid 1px rgba(94, 107, 121, 0.2);


  .setting-box-options-overflow{
    max-height: 210px;
    overflow-x: hidden;
    overflow-y: scroll;
    //padding-right: 1px;
    &::-webkit-scrollbar-track {
      webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #F5F5F5;
    }

    &::-webkit-scrollbar {
      width: 4px;
      background-color: #F5F5F5;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #dde3eb;
    }
  }
  .setting-box-options {
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    align-items: center;

    &:hover {
      color: #2d3740;
    }
    .setting-box-options-title{
      font-size: 16px;
      color: #5e6b79;
      text-overflow: ellipsis;
      width: 180px;
      white-space: pre;
      overflow: hidden;
    }

    .setting-box-options-sub-title{
      font-size: 11px;
      margin-top: 4px;
      font-style: italic;
      color: #8b95a1;

    }
  }

  .settings-box-add-new {
    width: 218px;
    height: 36px;
    border-radius: 5px;
    margin-top: 10px;

    display: flex;
    align-items: center;


    justify-content: space-around;

    cursor:pointer;color:#5e6b79;font-size:14px;
    border: dashed 2px #bfc8d2;
    &:hover{
      border-color: #5e6b79;
    }
    .te{
      font-size: 14px;
      color: #5e6b79;
    }
  }
}

.setting-box::after {
  content: '';
  position: absolute;
  left: 15px;
  top: -7.8px;
  width: 15px;
  height: 15px;
  border-top: solid 1px rgba(94, 107, 121, 0.2);
  border-left: solid 1px rgba(94, 107, 121, 0.2);
  box-shadow: 0 1 10px -5px rgba(45, 54, 64, 0.2);
  border-bottom: 0;
  border-right: 0;
  border-radius: 20% 0 0 0 / 25%;
  transform: rotate(45deg);
  background-color: #fff;
}

.add-list-area{
  display: flex;
  align-items: center;
  position: relative;

  .add-list-text{
    cursor: pointer;
    font-size: 15px;
    font-weight: 500;
    color: #2d3640;
    margin-left: 5px;
  }
  .add-list-text-red{
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    color: #fd4056;
    margin-left: 5px;
  }
  .add-list-icon-container{
    cursor: pointer;
    .add-list-icon /deep/{
      width: 20px;
      height: 20px;
      path{
        fill: #8b95a1;
      }
    }
    .add-list-icon-red /deep/{
      width: 20px;
      height: 20px;
      path{
        fill: #fd4056;
      }
    }
  }

  &:hover{
    .add-list-icon-container{
      .add-list-icon /deep/{
        width: 20px;
        height: 20px;
        path{
          fill: #5e6b79;
        }
      }
    }
  }
}


  .info-label{
    font-weight: 300;
    color: #8b95a1;
    font-size: 14px
  }

  .avatar-wrapper {
    position: relative;
    display: inline-flex;
    .status-icon {
      width: 14px;
      height: 14px;
      border-radius: 50%;
      border: solid 2px #ffffff;
      background-color: #8b95a1;
      position: absolute;
      right: 11px;

    }
    .avatar {
      box-shadow: 0 0 0 30px rgba(127, 173, 244, 0.05), 0 0 0 20px rgba(127, 173, 244, 0.08), 0 0 0 10px rgba(127, 173, 244, 0.1);
      width: 100px;
      height: 100px;
      border-radius: 50%;
    }
  }

  .username {
    font-size: 18px;
    color: #2d3640;
    font-weight: bold;
    margin-top: 40px;
    margin-bottom:0;
  }

  .userTitle {
    color: #8b95a1;
    font-style: italic;
    font-size: 16px;
    font-weight: 300;
    margin-top: 5px;
  }

  .star-rating /deep/{
    width: 18px;
    height: 18px;
    path {
      fill: #ffbf00
    }
  }

  .rating-number {
    font-weight: bold;
    font-size: 16px;
    color: #ffbe00;
    margin-left: 5px;
  }

  .comment-count {
    font-size: 16px;
    font-weight: 500;
    color: #2d3640;
  }

  .or-wrapper{
    display: flex;
    font-size: 12px;
    font-style: italic;
    color: #8b95a1;
    justify-content: center;
    margin-top: 25px;
    .dash{
      width: 20px;
      height: 1px;
      background-color: #8b95a1;
      margin: auto 3px;
    }
  }

  .freelancer-line-wrapper{
    margin-top: 26px;
    text-align: left;
    .info{
      display: flex;
      justify-content: space-between;
      .text-wrapper{
        font-size: 14px;
        display: flex;
        .text-general{
          margin: 0 10px;
          color: #8b95a1;
          font-weight: 300;
        }
      }
    }
  }

  .question-mark-wrapper {
    cursor: help;
    font-size: 12px;
    color: #2d3640;
    font-weight: 300;
    .question-mark-icon /deep/ {
      width: 18px;
      height: 18px;
      path {
        fill: #2d3640
      }
    }
  }

  .settings-wrapper {
    cursor: pointer;
    font-size: 12px;
    color: #2d3640;
    font-weight: 300;
    .icon /deep/ {
      width: 18px;
      height: 18px;
      path {
        fill: #2d3640
      }
    }
  }

  .option-wrapper{
    width: 353px;
    height: 56px;
    border-radius: 5px;
    border: solid 1px #eaedf2;
    background-color: rgba(244, 245, 247, 0.2);
    margin-top: 30px;
    font-size: 13px;
    font-weight: 500;
    color: #5e6b79;
    .option {
      width: 166px;
      height: 36px;
      line-height: 36px;
      cursor: pointer;
    }
    .active {
      background-color: #fd4056;
      color: #ffffff;
    }
  }

  .info-icons /deep/{
    height: 18px;
    width: 18px;
    path {
      fill: #bfc8d2;
    }
  }

  .admino-link-div {
    position: absolute;
    vertical-align: top;
    margin-top: 30px;
    margin-left: 10px;
    .admino-link {
      color: #FFF;
      background-color: #fd4056;
      border: 0;
      padding: 4px 6px;
      margin-top: 10px;
      font-size: 12px;
    }
  }

  .user-info-rating-div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 9px;
  }

  /* buttons*/
  .send-message-button {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
    outline: none!important;
    border: none;
    width: 290px;
    height: 56px;
    border-radius: 2px;
    background-color: #2d3640;
    font-size: 18px;
    line-height: 16px;
    font-weight: 600;
    text-align: center;
    color: #ffffff;
    margin-top: 30px;
    &:hover {
      background-color: #fd4056 !important;
      outline: none!important;
    }
   &:focus {
      outline: none!important;
    }
  }

  .add-phone-button {

    width: 290px;
    height: 56px;
    border-radius: 2px;
    border: solid 1px #bfc8d2;
    cursor: pointer;
    &:hover{
      background-color: #eaedf2!important;
    }
    .text {
      text-align: center;
      font-size: 18px;
      color: #2d3640;
      line-height: 56px;
      font-weight: 600;
    }
  }

  .line-status-button{
    width: 179px;
    height: 28px;
    border-radius: 2px;
    background-color: #fd4056;
    color: #ffffff;
    font-weight: 600;
    text-align: center;
    font-size: 13px;
    line-height: 28px;
  }

  .followers-count{
    width: 160px;
    height: 36px;
    border-radius: 2px;
    border: dashed 1px #bfc8d2;
    line-height: 35px;
    text-align: center;
    cursor: pointer;

    .inner-text {
      height: 18px;
      font-size: 14px;
      text-align: center;
      color: #5e6b79;
    }
    &:hover{
      border: solid 1px #2d3740;
    }
  }

  @keyframes loading {
    10% {
      opacity: .4;
    }
    2% {
      opacity: .1;
    }
  }

  .profile-loader {
    animation-name: loading;
    animation-duration: 2.5s;
    animation-iteration-count: infinite;
  }

  .information-section-1 {
    background-color: white;
    padding: 10px 20px 0;
  }

  .information-section-1-loader-avatar {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background-color: #dfe4ec;
  }

  .information-section-1-loader-username {
    margin-top: 20px;
    width: 180px;
    height: 20px;
    background-color: #dfe4ec;
  }

  .information-section-1-loader-title {
    margin-top: 10px;
    width: 280px;
    height: 12px;
    background-color: #dfe4ec;
  }

  .information-section-1-loader-stars {
    margin-top: 15px;
    width: 100px;
    height: 18px;
    background-color: #d9dfe5;
  }

  .followers-div-loader {
    margin-top: 40px;
    width: 300px;
    height: 50px;
    background-color: #dfe4ec;
  }

  .general-info-icon-loader {
    margin-bottom: 10px;
    width: 120px;
    height: 16px;
    background-color: #dfe4ec;
  }

  .general-info-loader {
    margin-bottom: 10px;
    width: 80px;
    height: 16px;
    background-color: #dfe4ec;
  }

</style>
