<template>
  <div v-if="!loadedPage" class="seller-portfolio-wrapper">
    <div style="display: flex; justify-content: space-between; width: 100%; align-items: center">
      <div style="display: flex; align-items: center">
        <span style="font-size: 20px; color: #2d3640">Portfolyo</span>
        <span class="new-button" style="margin-left: 10px; margin-top: 4px">
          <div style="margin-bottom: 2px">Yeni</div>
        </span>
      </div>
    </div>
    <div style="margin-top: 20px; display: flex;margin-left: -10px;">
      <div v-for="(x, index) in 4" :key="index" class="profile-loader" style="display: flex; ">
        <div class="portfolio-loader"/>
      </div>
    </div>
  </div>
  <div v-else-if="isSelfUser || portfolios.length" class="seller-portfolio-wrapper">
    <div style="display: flex; justify-content: space-between; width: 100%; align-items: center">
      <div style="display: flex; align-items: center">
        <span style="font-size: 20px; color: #2d3640">{{ isSelfUser ? 'Portfolyo' : 'Portfolyosu' }}</span>
        <span class="new-button" style="margin-left: 10px; margin-top: 4px">
          <div style="margin-bottom: 2px">Yeni</div>
        </span>
      </div>
      <router-link v-if="portfolios.length" :to="`/portfolyo/${userProfile.username}`" style="display: block; color: #00a575; font-size: 14px;">Portfolyoyu Görüntüle</router-link>
    </div>
    <div v-if="portfolios.length" style="margin-top: 20px; width: 708px; display: flex ">
      <div  @click="() => showPortfolioDetailModal(portfolio)" class="portfolio-container" v-for="(portfolio, index) in portfolios" :key="index">
      <img :src="portfolio.image_url_small" class="portfolio-img"/>
      </div>
    </div>
    <div v-else-if="isSelfUser"  class="arrow_box" style="margin-top: 20px;">
      <div style="height: 46px; display: flex; margin: 24px 18px 24px 26px">
        <img src=" https://gcdn.bionluk.com/site/cicons/ic-my-portfolio.svg" onload="SVGInject(this)" class="portfolio-icon" style="margin: auto 0;"/>
        <div style="display: flex; flex-direction: column; justify-content: space-between; margin-left: 14px">
          <div style="font-size: 20px; font-weight: bold; color: #2d3640;">Gösterilecek bir portfolyon yok 😞</div>
          <div style="font-size: 14px; font-weight: 300; color: #2d3640;">Neler yapabildiğini alıcılara göstermek için etkileyici bir portfolyo oluştur.</div>
        </div>
        <div class="create-portfolio-button" style="margin-left: 12px" @click="$router.push('/panel/portfolyo/yeni')">
          Oluştur
        </div>
      </div>
    </div>
  </div>
  <div v-else></div>
</template>

<script>
  export default {
    name: "ProfilePortfolioSection",
    props: {
      userProfile: {
        type: Object,
        default: () => ({})
      },
      portfolios: {
        type: Array,
        default: () => []
      },
      allItems:{
        type: Array,
        default: () => []
      },
      loadedPage: {
        type: Boolean,
        default: () => false
      }
    },
    computed: {
      isSelfUser() {
        return this.user.username === this.userProfile.username
      }
    },
    methods: {
      showPortfolioDetailModal(portfolio) {
        this.$store.state.showCase.data = portfolio;
        this.$store.commit(this.types.ACTIVE_MODAL, {modalType: this.Modals.PORTFOLIO, info: {uuid: portfolio.uuid,allItems:this.allItems}});
      }
    }
  }
</script>

<style lang="scss" scoped>


  .seller-portfolio-wrapper{
    padding: 30px 25px;
    color: #2d3640;
    font-size: 18px;
    font-weight: 500;
    background-color: white;
    border: 1px solid #e8e8e8;
    margin-bottom: 0;
    .new-button{
      width: 40px;
      height: 18px;
      text-align: center;
      border-radius: 10px;
      background-color: #fd4056;
      color: #ffffff;
      font-size: 11px;
      display: flex;
      justify-content: center;
      flex-direction: column;
    }
    .portfolio-container{
      height: 94px;
      border-radius: 5px;
      box-shadow: 0 0 10px 0 rgba(45, 54, 64, 0.05);
      border: solid 1px #eaedf2;
      background-color: #ffffff;
      cursor: pointer;
      &:nth-child(n+2) {
        margin-left: 7px;
      }

      .portfolio-img {
        height: 94px;
        border-radius: 5px;
      }

    }


  }

  .arrow_box {
    position: relative;
    background: rgba(239, 243, 248, 0.5);
    border-radius: 10px;
    border: solid 1px #eaedf2;
    width: 690px;
    height: 98px;
  }
  .arrow_box:after, .arrow_box:before {
    content: ' ';
    bottom: 100%;
    left: 24px;
    border: solid transparent;
    height: 0;
    width: 2px;
    position: absolute;
    pointer-events: none;
  }
  .arrow_box:after {
    border-radius: 2px;
    border-color: rgba(239, 243, 248, 0);
    border-bottom-color: rgba(239, 243, 248, 1.5);
    border-width: 7px;
    margin-left: -7px;
  }

  .arrow_box:before {
    border-color: rgba(234, 237, 242, 0);
    border-bottom-color: #eaedf2;
    border-width: 8px;
    margin-left: -8px;
  }

  .portfolio-icon /deep/{
    width: 33px;
    height: 36px;
    path {
      fill: #fd4056
    }
  }

  .create-portfolio-button{
    width: 130px;
    height: 46px;
    line-height: 44px;
    border-radius: 2px;
    background-color: #2d3640;
    color: #ffffff;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    cursor: pointer;

    &:hover{
      opacity: 0.9;
    }
  }

  @keyframes loading {
    10% {
      opacity: .4;
    }
    2% {
      opacity: .1;
    }
  }

  .profile-loader {
    animation-name: loading;
    animation-duration: 2.5s;
    animation-iteration-count: infinite;
  }

  .portfolio-loader {

    width: 164px;
    height: 94px;
    margin-left: 10px;
    border-radius: 5px;
    box-shadow: 0 0 10px 0 rgba(45, 54, 64, 0.05);
    border: solid 1px #eaedf2;
    cursor: pointer;
    background-color: #dfe4ec;
  }

</style>
