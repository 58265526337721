<template>
  <div class="follow-section">
    <div class="share-wrapper" style="margin: 24px auto 0">
      <div @click="clickedShareButton"  class="share-area">
        <p class="text">Paylaş</p>
        <social-sharing :url="url" inline-template>
          <div class="user-socialmedia-icons">
            <network network="facebook" style="cursor: pointer;">
              <img  style="width: 26px; height: 26px; border-radius: 50%" src="https://gcdn.bionluk.com/site/cicons/png/facebook.png"/>
            </network>

            <network  network="twitter" style="cursor: pointer; margin-left: 20px;">
              <img style="width: 26px; height: 26px; border-radius: 50%" src="https://gcdn.bionluk.com/site/cicons/png/twitter.png"/>
            </network>

            <network network="linkedin" style="cursor: pointer; margin-left: 20px;">
              <img style="width: 26px; height: 26px; border-radius: 50%" src="https://gcdn.bionluk.com/site/cicons/png/linkedin.png"/>
            </network>
          </div>
        </social-sharing>
        <div @click="copyToClipboard()" style="margin-left: 20px;">
          <img  class="share-icon" style="width: 26px; height: 26px; border-radius: 50%; cursor: pointer" src="https://gcdn.bionluk.com/site/cicons/png/link.png"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "FollowAndShareExternalUserSection",
    props: {
      userProfile: {
        type: Object,
        default: () => {}
      },
      initialFollowStatus: {
        type: Object,
        default: () => {}
      }
    },
    data() {
      return {
        url: window.location.href,
        followStatus: {
          action: 1,
          actionClass: "follow-button",
          actionText: "Takip Et"
        },
        followerCount: 0
      }
    },
    watch: {
      "initialFollowStatus.action": function () {
        this.setInitialData()
      }
    },
    methods: {
      setInitialData() {
        this.followerCount = this.userProfile.followerCount
        this.followStatus.action = this.initialFollowStatus.action
        this.followStatus.actionClass = this.initialFollowStatus.actionClass
        this.followStatus.actionText = this.initialFollowStatus.actionText
      },
      copyToClipboard(){
        const el = document.createElement('textarea');
        el.value = 'https://bionluk.com/'+this.userProfile.username;
        el.setAttribute('readonly', '');
        el.style.position = 'absolute';
        el.style.left = '-9999px';
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        this.$toasted.global.infoToast({description: 'Profil linki kopyalandı.'});
      },
      clickedShareButton(){
        this.Helper.trackEvents.customEvent("clicked", {version:'v4', page:this.$store.state.componentMap.page, what:'gig share buttons'});
      },
      reverseFollowStatus(action) {
        if (action === 1) {
          this.followStatus.action = -1
          this.followStatus.actionClass = "unfollow-button"
          this.followStatus.actionText = "Takip Ediliyor"
        } else if (action === -1) {
          this.followStatus.action = 1
          this.followStatus.actionClass = "follow-button"
          this.followStatus.actionText = "Takip Et"
        }
      },
      followAction(action) {
        this.reverseFollowStatus(action);
        this.disableFollowButton = true;
        this.api.user.updateFollowStatus(this.userProfile.uuid, action, this.$store.state.componentMap.page, this.$Progress)
          .then(({data: result}) => {
            if (result.success) {
              this.$emit('follow-action', {followStatus: this.followStatus, action })
            } else {
              this.reverseFollowStatus(this.followStatus.action);
              this.$toasted.global.errorToast({description: result.message});
              this.$router.push(result.data.redirect_url);
            }
          })
          .catch(() => {
            this.reverseFollowStatus(this.followStatus.action);
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          })
          .finally(() => {
            this.disableFollowButton = false;
          });
      },
    },
    created() {
      this.setInitialData()
    }
  }
</script>

<style lang="scss" scoped>
  .follow-section{
    width: 393px;
    height: 72px;
    border-radius: 5px;
    border: solid 1px #eaedf2;
    background-color: #ffffff;
    .follow-section-button {
      width: 290px;
      height: 56px;
      border-radius: 2px;
      border: solid 1px #bfc8d2;
      cursor: pointer;
      &.active{
        background-color: #eaedf2;
      }
      &:hover{
        background-color: #eaedf2;
      }
      .text {
        font-size: 18px;
        color: #2d3640;
        line-height: 56px;
        font-weight: 600;
      }
    }
    .share-wrapper {
      width: 240px;
      height: 26px;
      .share-area{
        display: flex;
        .user-socialmedia-icons {
          display: flex;
          flex-direction: row;
          height: 26px;
        }
        .text{
          margin-right: 20px;
          font-size: 18px;
          font-weight: normal;
          line-height: 1.33;
          color: #8b95a1;
        }
      }
    }
  }
</style>
