<template>
  <div class="section">
    <div v-if="showEditorButton" style="display: flex; justify-content: flex-end">
      <p style="font-size: 14px; text-decoration: underline; color: #fa4359; cursor: pointer" @click="showBriefDenyModal = true">Düzenle</p>
    </div>
    <template v-if="isSelfUser || userProfile.description">
      <label v-if="!isSelfUser" class="profile-section-label">
        <span  style="color: #fd4056">{{ userProfile.username }} </span>Hakkında
      </label>
      <label v-else class="profile-section-label" style="display: flex; justify-content: space-between">
        Hakkında
        <span class="action-add" @click="$router.push('/settings?section=1&open=1')">{{ userProfile.description ? 'Düzenle' : 'Ekle'}}</span>
      </label>
      <div style="color: #8b95a1; font-size: 16px; line-height: 1.5; word-wrap: break-word; margin-top: 20px">
        {{ userProfile.description || noDescriptionText }}
      </div>
    </template>
    <template v-if="(isSelfUser && userProfile.is_seller) || userProfile.seller_skills.length">
      <div class="line" style="margin-top: 30px"></div>
      <label class="profile-section-label" style="margin-top: 30px; display: flex; justify-content: space-between">
        Uzmanı Olduğu Alanlar & Araçlar
      </label>
      <div style="margin-top: 30px">
        <span v-for="skill in userProfile.seller_skills" class="skill-tag">{{ skill.name }}</span>
        <span v-if="isSelfUser" @click="$router.push(userProfile.seller_skills.length < 6 ? '/settings?section=3&open=1' : '/settings?section=3&open=0')" class="skill-tag" style="color: #26ca9a; border: solid 1px #26ca9a; cursor: pointer">{{ userProfile.seller_skills.length < 6 ? '+ Ekle' : 'Düzenle' }}</span>
      </div>
    </template>

    <template v-if="(isSelfUser && userProfile.is_seller) || userProfile.educationHistory.length">
      <div class="line" style="margin-top: 30px"></div>
      <label class="profile-section-label" style="margin-top: 30px; display: flex; justify-content: space-between">
        <span>Eğitim ve Sertifika Bilgileri</span>
        <span v-if="isSelfUser" class="action-add" @click="$router.push(userProfile.educationHistory.length ? '/settings?section=4&open=0' : '/settings?section=4&open=1')">{{userProfile.educationHistory.length ? 'Düzenle' : 'Ekle'}}</span>
      </label>
      <div style="margin-top: 20px">
        <div v-if="!userProfile.educationHistory.length && isSelfUser" style="font-size: 16px; color: #8b95a1; line-height: 1.5">
          Eğitim bilgilerini veya varsa katıldığın sertifika programlarını eklemen alıcıların tercihte bulunmaları ve daha kolay teklif alman için faydalı olabilir.
        </div>
        <div v-for="history in userProfile.educationHistory" style="font-size: 14px; margin-top: 20px">
          <p style="font-size: 16px; color: #5e6b79;">{{ history.school }}</p>
          <p style="color: #8b95a1; margin-top: 5px;">{{ `${history.department}, ${history.endYear}` }}</p>
        </div>
      </div>
    </template>
    <template v-if="userProfile.workHistory.length">
      <div class="line" style="margin-top: 30px"></div>
      <label style="font-size: 16px; font-weight: 600; margin-top: 30px">Projects</label>
      <div class="hiddendiv10"></div>
      <div v-for="history in userProfile.workHistory" style="font-size: 14px;">
        {{ history.title }}
        <p style="color: #bfc8d2;  margin-bottom: 5px;">{{ history.content }}</p>
      </div>
    </template>
    <template v-if="showBriefDenyModal">
      <transition name="modalLikes">
        <div @click="showBriefDenyModal = false" class="modalLikes-mask" style="overflow-y: scroll;">
          <div class="modalLikes-container"
               style="padding: 20px 10px; font-family: 'sofia-pro'; margin-top:40px; width:720px!important; height: 420px; overflow-y: scroll; " @click.stop>
            <div>
              <div style="z-index: 9997; float: right; ">
                <img @click="showBriefDenyModal = false" style=" opacity: .6; cursor:pointer;width: 14px; height: 14px;"
                     :src="$root.CDN_SITE + 'icon/ic_close_black.svg'">
              </div>
              <div style="clear: both; height: 10px;"></div>
              <div style="padding: 5px; margin-left: 0;">
                <h4 style="margin-bottom:12px;font-size: 16px; font-weight: 400; color: #2d3640;">Aşağıdaki alanlardan iletişim bilgisini kaldır</h4>
                <custom-input label="Başlık"
                              place-holder="Örn: Tasarımcı"
                              inputType="textArea"
                              v-model="title"
                              :valid="title !== userProfile.title ? true : null"
                              maxlength="60"
                              style="margin-bottom: 40px; height: 45px; width: 393px;"/>
                <custom-input label="Hakkında"
                              inputType="textArea"
                              v-model="brief"
                              :valid="brief !== userProfile.description ? true : null"
                              maxlength="600"
                              style="width:658px; height: 200px;"/>
                <div style="display: flex; justify-content: end; margin-top: 30px">
                  <custom-button :disabled="buttonLoading || (brief.trim() === userProfile.description && title.trim() === userProfile.title)"  buttonType="red" style="width: 128px; height: 38px; font-size: 14px; padding-bottom: 2px;" @click="notifyAndSaveBrief">Kaydet</custom-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </template>
  </div>
</template>

<script>
  import CustomInput from "../../../../../components/CustomInput"
  import CustomButton from "../../../../../components/CustomButton"
  import Api from '@/utils/request'

  export default {
    name: "UserAbout",
    components: { CustomInput, CustomButton },
    props: {
      userProfile: {
        type: Object,
        default: () => ({})
      }
    },
    data() {
      return {
        buttonLoading: false,
        showBriefDenyModal: false,
        title: '',
        brief: '',
        noDescriptionText: "Alıcıların seni ve yaptığın işleri daha yakından tanıması için yazacağın birkaç satırlık tanıtım yazısı, daha kolay teklif alman için faydalı olacaktır."
      }
    },
    watch: {
      userProfile() {
        this.init()
      },
      showBriefDenyModal() {
        this.init()
      }
    },
    computed: {
      isSelfUser() {
        return this.user.username === this.userProfile.username
      },
      showEditorButton() {
        return this.user && this.user.editorRoles && this.user.editorRoles.indexOf("profile") > -1 && !this.isSelfUser
      }
    },
    methods: {
      init() {
        this.brief = this.userProfile.description || ''
        this.title = this.userProfile.title || ''
      },
      notifyAndSaveBrief() {
        this.buttonLoading = true
        return Api.post('/editors/deny-profile-brief', { username: this.userProfile.username, brief: this.brief, title: this.title })
            .then(({ data: credit }) => {
              this.$toasted.global.infoToast({ description: `Teşekkürler, ${credit} kredi yüklendi.` })
              setTimeout(() => location.reload(), 1000)
            })
            .catch(err => {
              this.buttonLoading = false
              if ([403, 404].includes(err.status)) {
                this.$toasted.global.errorToast({ description: err.data.message })
              } else {
                this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE})
              }
            })
      }
    },
    created() {
      this.init()
    }
  }
</script>

<style lang="scss" scoped>
  .section {
    border: 1px solid #eaedf2;
    background-color: white;
    padding: 30px;
    text-align: left;
    .profile-section-label {
      font-weight: 500;
      font-size: 20px;
      color: #2d3640;
    }
    .skill-tag {
      color: #8b95a1;
      border-radius: 4px;
      border: solid 1px #8b95a1;
      display: inline-block;
      position: relative;
      min-width: 40px;
      text-align: center;
      padding: 5px 12px 7px;
      margin: 0 10px 10px 0;
      font-size: 14px;
    }
    .action-add {
      font-size: 14px;
      font-weight: 500;
      color: #00a575;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      cursor: pointer;
    }
  }
</style>
