<template>
  <div>
    <div v-if="1===2 & !isGigPageLoaded" class="seller-portfolio-wrapper">
      <div style="font-size: 20px; color: #2d3640; display: flex; justify-content: space-between">
        <div>
          <span style="color: #fd4056"></span>
        </div>
      </div>
      <div style="margin-top: 20px; display: flex; justify-content: center;">
        <div style="display: flex; flex-wrap: wrap; justify-content: space-between; width: 640px">
          <div v-for="x in 4" class="profile-gig-body">
            <div class="card-image-container profile-loader">
            </div>
            <div class="card-detail">
              <div class="card-detail-container">
                <div class="card-detail-header-large profile-loader">
                </div>
                <div class="card-detail-header-small profile-loader">
                </div>
              </div>
              <div class="card-detail-user-container ">
                <div class="card-detail-user">
                  <div class="card-detail-user-image profile-loader"></div>
                  <div class="card-detail-user-name profile-loader"></div>
                </div>
                <div class="card-detail-currency profile-loader">
                </div>
              </div>
              <div>
              </div>
            </div>
            <div class="profile-loader"></div>
            <div class="card-like-currency">
              <div class="card-like-icon profile-loader"></div>
              <div class="card-currency-icon profile-loader"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <template v-else-if="userProfile && ((userProfile.is_seller && gigs.length) || (userProfile.is_seller && isSelfUser))">
      <div class="seller-portfolio-wrapper">
        <div style="font-size: 20px; color: #2d3640; display: flex; justify-content: space-between">
          <div>
            <span v-if="!isSelfUser" style="color: #fd4056">{{userProfile.username}}</span> {{ isSelfUser ? 'Verdiğin Hizmetler' : 'Kullanıcısının Verdiği Hizmetler' }}
          </div>
          <router-link v-if="isSelfUser" to="/panel/ilanlar" style="color: #00a575; font-size: 14px;">Tüm ilanlarına git</router-link>
        </div>
        <div v-if="gigs.length" style="margin-bottom: 20px; display: flex; justify-content: center;">
          <div style="display: flex; flex-wrap: wrap; justify-content: space-between; width: 604px">
            <single-gig :impression-u-u-i-d="impressionUUID" v-for="(gig, index) in gigs" :key="index" :item="gig" class="gig-content" style="margin-top: 30px"/>
            <single-gig v-if="isSelfUser && gigs.length >= 1 && $store.state.user.info.total_bicoin === 1000" type="bicoin" class="gig-content" style="margin-top: 30px"/>
            <single-gig v-if="isSelfUser && !user.seller.oldGigCount" :item="null" class="gig-content" style="margin-top: 30px"/>
          </div>
        </div>
        <div v-else-if="isSelfUser" style="margin-top: 40px; text-align: center">
          <img src="https://gcdn.bionluk.com/site/cicons/il-first-gig.png" style="width: 335px; height: 183px;">
          <div v-if="user.seller.oldGigCount" style="margin-top: 50px; color: #2d3640; font-weight: bold; font-size: 28px;">
            <span style="font-weight: normal;">İlanlarını</span> Yönet
          </div>
          <div v-else style="margin-top: 50px; color: #2d3640; font-weight: bold; font-size: 28px;">
            <span style="font-weight: normal;">İlk</span> İlanını Oluştur
          </div>
          <div v-if="user.seller.oldGigCount" style="font-size: 18px; color: #5e6b79; width: 543px; line-height: 1.44; margin: 15px auto 0;">
            Sevgili <span style="font-weight: bold;color: #2d3640;">{{ user.firstname || userProfile.username}}</span>, aktif ilanın yok gözüküyor. İlanlarım sayfasından tüm ilanlarını yönetebilirsin.
          </div>
          <div v-else style="font-size: 18px; color: #5e6b79; width: 543px; line-height: 1.44; margin: 15px auto 0;">
            Sevgili <span style="font-weight: bold;color: #2d3640;">{{ user.firstname || userProfile.username}}</span>, yeteneklerini sergilemek için etkileyici bir iş ilanı oluştur. Bionluk da sana iş bulsun!
          </div>
          <div v-if="user.seller.oldGigCount" @click="$router.push('/panel/ilanlar')" class="create-gig-button" style="margin: 40px auto 60px">
            İlanlarıma Git
          </div>
          <div v-else @click="$router.push('/panel/ilanlar/yeni')" class="create-gig-button" style="margin: 40px auto 60px">
            İş İlanı Oluştur
          </div>
        </div>
      </div>
      <div v-if="gigSeemorediv" @click="retrieveGigs(userProfile.username, gigLimit, gigOffset)" class="see-more-div">
        <loader v-if="gigLoader" style="width: 25px; height: 25px"/>
        <p v-else>Daha fazla göster</p>
      </div>
    </template>
  </div>
</template>

<script>
  import SingleGig from "../../../../../components/singleGig";
  export default {
    name: "ProfileGigSection",
    components: {SingleGig},
    props: {
      userProfile: {
        type: Object,
        default: () => ({})
      }
    },
    data() {
      return {
        impressionUUID:null,
        gigs: [],
        gigOffset: 0,
        gigLimit: 6,
        gigSeemorediv: false,
        gigLoader: false,
        isGigPageLoaded: false
      }
    },
    computed: {
      isSelfUser() {
        return this.userProfile ? this.user.username === this.userProfile.username : null
      }
    },
    watch: {
      '$store.state.routerParams': function (newVal) {
        if (newVal && newVal.length) {
          Object.assign(this.$data, this.$options.data())
          this.init()
        }
      }
    },
    methods: {
      init() {
        this.impressionUUID = this.Helper.generateUUID();
        this.username = this.$store.state.routerParams[0];
        this.retrieveGigs(this.username, 2 , this.gigOffset)
      },
      retrieveGigs(username, limit, offset) {
        this.gigLoader = true;
        this.api.general.getAllGigsByUser(username, limit, offset)
          .then(({data}) => {
            let result = data;
            if (result.success) {

              if(result.data.gigs.length > 0){
                let trackGigObj = [];
                result.data.gigs.forEach((gig, gigdex) => {
                  trackGigObj.push({
                    gig_id:gig.id,
                    seller_id:gig.seller_id,
                    order_id: gigdex + 1
                  })
                });
                let trackingProps = {
                  page:'userprofile',
                  placement:'default',
                  info:trackGigObj,
                  title: this.impressionUUID
                };
                this.Helper.trackEvents.customEvent("gig_impressions", trackingProps);
              }

              this.gigs = this.gigs.concat(result.data.gigs);
              this.gigOffset += limit;
              this.gigSeemorediv = result.data.seemorediv;
            } else {
              this.$toasted.global.errorToast({description: result.message});
            }
          })
          .catch(() => {
            this.gigLoader = false;
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          })
        .finally(() => {
          this.gigLoader = false;
          this.isGigPageLoaded = true;
        })
      }
    },
    created() {
      this.init()
    }
  }
</script>

<style lang="scss" scoped>
  .seller-portfolio-wrapper{
    padding: 30px;
    color: #2d3640;
    font-size: 18px;
    font-weight: 500;
    background-color: white;
    border: 1px solid #e8e8e8;
    margin-bottom: 0;
    .gig-content {
      cursor: pointer;
      &:nth-child(2n) {
        margin-left: 41px;
      }
    }
  }

  .see-more-div {
    display: flex;
    align-items: center;
    height: 68px;
    justify-content: center;
    border: 1px solid #e8e8e8;
    border-top: none;
    background-color: white;
  }

  .see-more-div p {
    font-size: 14px;
    cursor: pointer;
    font-weight: 600;
    color: #2d3640;
    vertical-align: middle;
    line-height: 60px;
    text-align: center;
  }

  .create-gig-button{
    width: 152px;
    height: 46px;
    line-height: 45px;
    border-radius: 2px;
    background-color: #fd4056;
    color: #ffffff;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    cursor: pointer;
    &:hover{
      opacity: 0.9;
    }
  }

  @keyframes loading {
    10% {
      opacity: .4;
    }
    2% {
      opacity: .1;
    }
  }

  .profile-loader {
    animation-name: loading;
    animation-duration: 2.5s;
    animation-iteration-count: infinite;
  }

  .card-detail-header-large {
    width: 214px;
    height: 16px;
    background-color: #e1e6ed;
    margin-bottom: 4px;
  }

  .card-detail-header-small {
    width: 117px;
    height: 16px;
    background-color: #e1e6ed;
  }

  .card-detail-user-image {
    width: 28px;
    height: 28px;
    background-color: #dfe4ec;
    border-radius: 50%;
    margin-right: 8px;
  }

  .card-detail-user-name {
    width: 50px;
    height: 16px;
    background-color: #e1e6ed;
  }

  .card-detail-currency {
    width: 63px;
    height: 16px;
    background-color: #e1e6ed;
  }

  .card-image-container {
    height: 148px;
    background-color: #fafbfc;
    border-bottom: solid 1px #dfe4ec;
  }
</style>
