<template>
  <div style="background-color: #f4f5f7;">
    <div class="profile-page-body">
      <div class="information-body" style="text-align: center">
        <ShareInternalUserSection v-if="isSelfUser" :user-profile="userProfile" style="margin-bottom: 30px"/>
        <ProfileInfo :is-page-loaded="isPageLoaded" :user-profile="userProfile" :admino-link="adminoLink" :initial-follow-status="followStatus" class="profile-info-section" />
        <FollowAndShareExternalUserSection v-if="!isSelfUser && isPageLoaded && userProfile && userProfile.is_seller" :initial-follow-status="followStatus" :user-profile="userProfile" @follow-action="updateFollowStatus" style="margin-top: 30px"/>
        <UserAbout v-if="isPageLoaded && (userProfile && (userProfile.description || userProfile.seller_skills.length || userProfile.educationHistory.length || userProfile.workHistory.length))" :user-profile="userProfile" style="margin-top: 30px"/>
        <div v-if="isPageLoaded" class="report-div">
          <img src="https://gcdn.bionluk.com/site/cicons/ic-report.svg" onload="SVGInject(this)" class="report-svg"/>
          <p @click="report('user', userProfile.user_id, 16)"> {{ isSelfUser ? 'Hata bildir' : 'Bu kullanıcıyı bildir' }}</p>
        </div>
      </div>
      <div class="profile-section-body">
        <div v-if="isSelfUser && userProfile && !userProfile.is_seller && !userProfile.description" class="buyer-desc-empty-state">
          <label class="profile-section-label" style="display: flex; justify-content: space-between">
            Hakkında
            <span class="action-add" @click="$router.push('/settings?section=1&open=1')">Ekle</span>
          </label>
          <div class="empty-desc">
            Freelancer’ların seni tanıması için yazacağın birkaç satırlık tanıtım yazısı, açmış olduğun özel alıcı isteklerine daha kolay teklif alman ve talep ettiğin hizmeti en hızlı biçimde tedarik etmen için faydalı olacaktır.
          </div>
        </div>
        <ProfilePortfolioSection v-if="userProfile && userProfile.is_seller" :user-profile="userProfile" :portfolios="portfolios" :allItems="allPortfolioUUIDs" :loaded-page="isPortfolioLoaded" :style="!isPortfolioLoaded || portfolios.length || (isSelfUser && userProfile.is_seller) ? 'margin-bottom: 30px' : 'margin-bottom: 0px'"/>
        <ProfileGigSection :user-profile="userProfile" />
        <ReviewSection v-if="userProfile" :user-profile="userProfile" :initialCommentType="userProfile && userProfile.is_seller ? 'seller' : 'buyer' " :style="userProfile && userProfile.is_seller  ? ((isSelfUser || userProfile.is_seller && userProfile.gigCount !== '0') ? 'margin-top:30px' :  'margin-top:0') : 'margin-top:0px'"/>
      </div>
    </div>
  </div>
</template>

<script>
  import ProfileInfo from "./components/ProfileInfo";
  import FollowAndShareExternalUserSection from "./components/FollowAndShareExternalUserSection";
  import ShareInternalUserSection from "./components/ShareInternalUserSection";
  import UserAbout from "./components/UserAbout";
  import ProfilePortfolioSection from "./components/ProfilePortfolioSection";
  import ProfileGigSection from "./components/ProfileGigSection";
  import ReviewSection from "./components/ReviewSection";

  export default {
    name: "src-pages-body-profile-desktop-v4",
    components: {
      ReviewSection,
      ProfileGigSection,
      ProfilePortfolioSection,
      UserAbout,
      ShareInternalUserSection,
      FollowAndShareExternalUserSection,
      ProfileInfo
    },
    data() {
      return {
        isPageLoaded: false,
        userProfile: null,
        followStatus: {
          action: 1,
          actionClass: "follow-button",
          actionText: "Takip Et"
        },
        uuid: null,
        portfolios: [],
        allPortfolioUUIDs:[],
        portfolioOffset: 0,
        portfolioLimit: 4,
        isPortfolioLoaded: false,
        username: null,
        pageVersion: "v1",
        adminoLink: false,
        showRecurringBuyer: false,
        recurring_buyers: 0
      }
    },
    methods: {
      updateFollowStatus({ followStatus, action }) {
        this.userProfile.followerCount += action
        this.followStatus.action = followStatus.action
        this.followStatus.actionClass = followStatus.actionClass
        this.followStatus.actionText = followStatus.actionText
      },
      showPortfolioDetailModal(portfolio) {
        this.$store.state.showCase.data = portfolio;
        this.$store.commit(this.types.ACTIVE_MODAL, {modalType: this.Modals.PORTFOLIO, info: {uuid: portfolio.uuid}});
      },
      retrievePortfolios(offset, limit) {
        this.isPortfolioLoaded = false;
        this.api.seller.listPortfolios(this.username, this.Constants.PORTFOLIO_STATUSES.ACTIVE, limit, offset)
          .then(({data: result}) => {
            if (result.success) {
              this.portfolios = this.portfolios.concat(result.data.portfolios);
              this.allPortfolioUUIDs = result.data.all_portfolio_uuids;
              this.portfolioOffset += limit;

              if (this.$store.state.showCase.byModal && this.$store.state.showCase.to === this.$store.state.route.from.fullPath) {
                this.$nextTick(() => {
                  this.showPortfolioDetailModal(this.$store.state.showCase.data);
                });
              }
            } else {
              this.$toasted.global.errorToast({description: result.message});
            }
          })
          .catch(() => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          })
          .finally(() => {
            this.isPortfolioLoaded = true;
          })
      },
      retrieveUser() {
        return this.api.general.retrieveUser(this.username)
          .then(({data: result}) => {
            if (result.success) {
              this.userProfile = result.data.user;
              this.$store.state.indexHTMLTitle = this.userProfile.meta.title;
              this.$store.state.indexHTMLMetaDescription = this.userProfile.meta.description;
              this.setFacebookMetaProperties(result.data.user.meta.facebookMeta);
              this.followStatus = result.data.followStatus
              this.adminoLink = result.data.alk;
              this.showRecurringBuyer = result.data.user.show_recurring_buyers;
              this.recurringBuyers = result.data.user.recurring_buyers;
              this.Helper.trackEvents.pageView(this.Helper.createUserPageProps(result.data.user));
            } else {
              this.$store.commit(this.types.SET_COMPONENT_MAP, this.$store.state.routerMap["/*"])
            }
            return result
          })
          .catch(() => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          });
      },
      init() {
        this.isPageLoaded = false
        this.username = this.$store.state.routerParams[0]
        this.retrieveUser(this.username).then(() => {
          this.isPageLoaded = true;
          this.retrievePortfolios(this.portfolioOffset, this.portfolioLimit)
        })
      },
      report(type, external_id, report_type_id) {
        if (this.user.uuid) {
          this.$store.commit(this.types.ACTIVE_MODAL, {modalType: this.Modals.COMPLAINT, info: {type: type, externalId: external_id, reportTypeId: report_type_id}});
        } else {
          this.$router.push('/login');
        }
      }
    },
    watch: {
      '$store.state.routerParams': function (newVal) {
        if (newVal && newVal.length) {
          Object.assign(this.$data, this.$options.data());
          this.init()
        }
      }
    },
    computed: {
      isSelfUser() {
        return this.userProfile ? this.user.username === this.userProfile.username : false
      }
    },
    created() {
      this.EventBus.$on('modalPortfolioUpdated', (payload) => {
        let portfolio = this.portfolios.find(({ uuid }) => uuid === payload.portfolioUuid)
        portfolio.comments.commentCount = payload.comments.commentCount;
        portfolio.fav_count = payload.favCount;
      });
      this.init()
    },
    beforeDestroy() {
      this.EventBus.$off('modalPortfolioUpdated');
    }
  }

</script>

<style scoped lang="scss">

  .report-svg /deep/{
    width: 18px;
    height: 18px;
    path {
      fill: #8b95a1
    }
  }

  .profile-page-body {
    width: 1170px;
    margin: 0 auto;
    padding: 30px 0 60px;
  }

  .information-body {
    display: inline-block;
    width: 392px;
  }

  .profile-info-section {
    border: 1px solid #eaedf2;
    background-color: white;
    padding: 40px 20px 20px;
  }

  .profile-section-label {
    font-size: 16px;
    font-weight: 600;
    color: #242424;
  }

  .report-div {
    margin-top: 24px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    img {
      position: absolute;
      margin-left: -66px;
    }
    p {
      color: #8b95a1;
      font-weight: normal;
      font-size: 18px;
      margin-left: 8px;
      &:hover{
        text-decoration: underline;
      }
    }
  }

  .profile-section-body {
    display: inline-block;
    vertical-align: top;
    margin-left: 30px;
    width: 740px;
  }

  .bounce-enter-active {
    animation: bounce-in .5s;
  }

  .bounce-leave-to {
    animation: bounce-out .0s;
  }

  @keyframes bounce-in {
    0% {
      transform: scale(0.2);
    }
    50% {
      transform: scale(1.3);
    }
    100% {
      transform: scale(1);
    }
  }

  @keyframes bounce-out {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.5);
    }
    100% {
      transform: scale(1);
    }
  }

  .buyer-desc-empty-state{
    width: 100%;
    margin-bottom: 30px;
    height: 181px;
    border-radius: 5px;
    border: solid 1px #eaedf2;
    background-color: #ffffff;

    .profile-section-label {
      padding: 30px 30px 19px 30px;
      font-weight: 500;
      font-size: 20px;
      color: #2d3640;
    }
    .empty-desc{
      font-size: 16px;
      line-height: 1.63;
      text-align: center;
      color: #8b95a1;
      max-width: 690px;
      margin:0 auto;
      display: flex;
      justify-content: center;
    }
    .action-add {
      font-size: 14px;
      font-weight: 500;
      color: #00a575;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      cursor: pointer;
    }
  }

</style>
